import _sassDart from "./sass.dart.js";
import * as _util2 from "util";
var _util = "default" in _util2 ? _util2.default : _util2;
import * as _immutable2 from "immutable";
var _immutable = "default" in _immutable2 ? _immutable2.default : _immutable2;
import * as _fs2 from "fs";
var _fs = "default" in _fs2 ? _fs2.default : _fs2;
var exports = {};
const library = _sassDart;
library.load({
  util: _util,
  immutable: _immutable,
  fs: _fs
});
exports = library;
export default exports;